export default function Career() {
    async function apply(evt) {
        evt.preventDefault();
        document.getElementById('career-spinner').style.display = '';
        let form = document.getElementById('career-form');
        let formData = new FormData(form);
        await fetch('https://timesheetadmin.macpro-technologies.com/website_email_career/', {
            method: "POST",
            body: formData
        }).then(res => res.json()).then(res => {
            if (res.success) {
                alert('Your application has been submitted successfully.')
            }
            else {
                console.log(res);
            }
        });
        document.getElementById('career-spinner').style.display = 'none';
    }
    return <div className="container contact">
        <div className="section-title" data-aos="zoom-in" data-aos-duration="900">
            <h2><strong>Apply now! </strong>to join MacPro Technologies</h2>
        </div>
        <h2> </h2>
        <div className="row mt-5 justify-content-center" data-aos="fade-up">
            <div className="col-lg-10">
                <form action="." method="post" role="form" id="career-form" className="php-email-form" onSubmit={(evt) => apply(evt)}>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <input type="text" className="form-control" name="first-name" placeholder="First Name" required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="text" className="form-control" name="last-name" placeholder="Last Name" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <input type="number" className="form-control" name="contact" placeholder="Contact number" required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="email" className="form-control" name="email" placeholder="Email address" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mt-3">
                            <textarea className="form-control" name="address" rows="3" placeholder="Address Line" required></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="qualification" placeholder="Qualification" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <input type="text" className="form-control" name="city" placeholder="City" required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="text" className="form-control" name="pincode" placeholder="Pin code" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <input type="text" className="form-control" name="country" placeholder="Country" required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="file" className="form-control" name="resume" required />
                        </div>
                    </div>
                    <div>
                        <center>
                            &nbsp;
                            <div id="career-spinner" style={{ "display": "none" }}>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            &nbsp;
                        </center>
                    </div>
                    <div className="text-center"><button type="submit">Apply now!</button></div>
                </form>
            </div>
        </div>
        <br />
        <br />
    </div>;

}