import React from "react";

export default function Facility() {
  return <React.Fragment>
    <section id="blog" className="blog">
      <div className="section-title" data-aos="zoom-in" data-aos-duration="900">
        <h2><strong>Facilities </strong>at MacPro Technologies</h2>
        <div className="btn-group" id="facility-btn-group" role="group" aria-label="Basic example">
          <a href="#dc" className="btn btn-sm btn-outline-info">Design Center</a>
          <a href="#ce" className="btn btn-sm btn-outline-info">CNC EDM</a>
          <a href="#we" className="btn btn-sm btn-outline-info">Wire EDM</a>
          <a href="#sg" className="btn btn-sm btn-outline-info">Surface Grinding</a>
          <a href="#vm" className="btn btn-sm btn-outline-info">Vertical Machining</a>
          <a href="#jg" className="btn btn-sm btn-outline-info">Jig Grinding</a>
          <a href="#im" className="btn btn-sm btn-outline-info">Injection Moulding</a>
          <a href="#qa" className="btn btn-sm btn-outline-info">Quality Assurance</a>
          <a href="#hss" className="btn btn-sm btn-outline-info">High Speed Stamping</a>
        </div>
      </div>
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">

          <div className="row">

            <div className="col-lg-12 entries">

              <article id="dc" className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/design center.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Design Center</a>
                  </h2>

                  <div className="entry-content">
                    <ol id="ce">
                      <li>Leading design softwares</li>
                      <li>CAE</li>
                      <li>Design reviews by experts & Review by Lesson learned</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/cnc edm.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">CNC EDM</a>
                  </h2>

                  <div className="entry-content">
                    <ol id="we">
                      <li>Global branded machines with 3R automation</li>
                      <li>Surface roughness up to 0.2Ra</li>
                      <li>Positional and dimensional accuracy ranging 0.003-0.007mm</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/wire edm.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Wire EDM</a>
                  </h2>

                  <div className="entry-content">
                    <ol>
                      <li>Global branded machines with 3R automation</li>
                      <li>Surface roughness up to 0.2Ra</li>
                      <li>Positional and dimensional accuracy ranging 0.003-0.007mm</li>
                      <li id="vm">EDM Drill up to 0.3 mm</li>
                      <li>Wires – 0.1. & 0.25mm</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/vertical machine center.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Vertical Machining Center</a>
                  </h2>

                  <div className="entry-content">
                    <ol>
                      <li>Global branded machines with 3R automation</li>
                      <li>Positional and dimensional accuracy &lt; 0.008 mm</li>
                      <li id="sg">High Speed spindles up to 40K RPM</li>
                      <li>Hard Part machining up to 60 HRC</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/surface grinding.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Surface Grinding</a>
                  </h2>

                  <div className="entry-content">
                    <ol id="jg">
                      <li>Steel and Carbide machining capability</li>
                      <li>Wheel forming</li>
                      <li>0.002~0.005 tolerance</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/jig grinding.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Jig Grinding</a>
                  </h2>

                  <div className="entry-content">
                    <ol id="im">
                      <li>High Speed spindle up to 140K RPM</li>
                      <li>Tolerance upto 0.003mm</li>
                      <li>Internal and external diameter grinding</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/injection.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Injection Moulding Machine</a>
                  </h2>

                  <div className="entry-content">
                    <ol id="hss">
                      <li>Hydraulic and Electric Machines Ranging 30T-200T </li>
                      <li>MTC’s upto 200 Degree</li>
                      <li>High Engineered polymer processing – PEEK/ULTEM</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img src="assets/img/facilities/high speed stamping.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">High Speed Stamping</a>
                  </h2>

                  <div className="entry-content">
                    <ol id="qa">
                      <li>Bruderer – 30T</li>
                      <li>Upto 600 SPM</li>
                    </ol>
                  </div>
                </center>
              </article>
              <article className="entry">
                <center>
                  <div className="entry-img">
                    <br />
                    <div className="row col-md-5">
                      <img  src="assets/img/facilities/quality.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>

                  <h2 className="entry-title">
                    <a href="#">Quality Assurance</a>
                  </h2>

                  <div className="entry-content">
                    <ol>
                      <li>CMM-Hexagon Metrology 600x500 travel, +/-0.002mm</li>
                      <li>Video measuring: MicroVu SOL</li>
                      <li>Digital HG : Tesa Microhite</li>
                    </ol>
                  </div>
                </center>
              </article>

            </div>

          </div>

        </div>
      </section>
    </section>

  </React.Fragment>
}