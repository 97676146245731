import React from "react";

export default function About() {
    const historyItems = [
        {
            'title': '2010',
            'date': '2010',
            'content': 'Incorporated in May 2010. Installed 2 conventional machines. Received the first export order to begin with. ',
            'growth': '0.34'
        },
        {
            'title': '2011',
            'date': '2011',
            'content': 'Installed 5 Axis Milling & Jig Grinder to enhance operational efficiency. Certified ISO 9001. Installed 3 Axis VMC which accelerated the mold making capacity. ',
            'growth': '224%'
        },
        {
            'title': '2012',
            'date': '2012',
            'content': 'Installed the First Injection moulding Machine- Arburg 80T. Started doing internal mould trials. ',
            'growth': '64%'
        },
        {
            'title': '2013',
            'date': '2013',
            'content': 'Started Part production. Won business for Ford part supply as  their Tier 2 supplier via M/s Kostal . ',
            'growth': '111%'
        },
        {
            'title': '2014',
            'date': '2014',
            'content': 'Certified TS 16949 & Certified VDA 6.3. Added additional injection moulding machines. Additional moulding business. ',
            'growth': '39%'
        },
        {
            'title': '2015',
            'date': '2015',
            'content': 'High-speed stamping die designed & manufactured for Tyco Electronics. Regular production of moulding and stamping increased by 30%. ',
            'growth': '47%'
        },
        {
            'title': '2016',
            'date': '2016',
            'content': 'High-speed stamping machine installed - Bruderer 30T. Regular production of moulding and stamping increased by 30%. ',
            'growth': '41%'
        },
        {
            'title': '2017',
            'date': '2017',
            'content': 'Moved to a larger space, from 5K sqft to 16K sqft. Added more CNC machines and injection moulding machines. Regular production of moulding and stamping increased by 30%. ',
            'growth': '39%'
        },
        {
            'title': '2018',
            'date': '2018',
            'content': 'Metrology department capacity enhanced- CMM + Microscopes. ',
            'growth': '5%'
        },
        {
            'title': '2019',
            'date': '2019',
            'content': 'Tampon printing equipment installed. Capacity enhanced to do sub-assembly. ',
            'growth': '28%'
        },
        {
            'title': '2020',
            'date': '2020',
            'content': 'Strategic partnership with M/s Terminal Technologies Pvt LTd. ',
            'growth': '-20%'
        }
    ]
    return <React.Fragment>
        <section id="overview" className="about-us">
            <div className="container">
                <div className="row no-gutters">
                    <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right">
                        <center>
                            <div className="col-sm-8">
                                <img src="assets/img/site-images/overview.jpg" className="img-fluid" alt="q" />
                            </div>
                        </center>
                    </div>
                    <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
                        <div className="container">
                            <div className="content d-flex flex-column">
                                <center>
                                    <h3 data-aos="fade-up" style={{ 'color': 'black' }}>Overview</h3>
                                </center>
                                <p data-aos="fade-up" className="text-center">
                                    Incorporated in May 2010, Started its operation with minimum machineries and design capabilities, with the industrial expertise MacPro has grown rapidly. With more than 11 years of excellence in High Precision, Plastic Injection molds, Stamping Dies, components and Sub Assemblies, we have a stellar group turnover of 200+ Crore. We’ve got significant global exposure in Precision Manufacturing & Design, Stamping and Setting up of assembly lines. We believe in the concepts of ‘Quality First’ and ‘Built to Print,’ the industrious following of which has helped us become a leading force in the tooling and production industry in the country.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="founder" className="blog">
            <div className="section-title" data-aos="fade-up">
                <h2 style={{ 'color': 'black' }}>Our <strong>Founders</strong></h2>
            </div>
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-12 entries">
                        <div className="blog-author d-flex align-items-center">
                            <img src="assets/img/founder-1.jpg" className="rounded-circle float-left" alt="" />
                            <div>
                                <h4>Padmanabhan R</h4>
                                <div className="social-links">
                                </div>
                                <p>
                                    Born & Brought up in Bangalore, Karnataka, with a vast experience on connectors & terminals, he's been in this field for over 23 years!
                                    During his career, he has associated with global leaders in connectors and inter connection systems & aquired expertise in global sourcing and localization.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 entries">
                        <div className="blog-author d-flex align-items-center">
                            <img src="assets/img/founder-2.jpg" className="rounded-circle float-left" alt="" />
                            <div>
                                <h4>Shijesh Kokkodan</h4>
                                <div className="social-links">
                                </div>
                                <p>
                                    With an experience of 21 years in this industry and hailing from Bangalore, he has associated with companies overseas and also is very well experienced in connectors,
                                    terminals and inter connection systems. His expertise in global sourcing and localization is remarkable!

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <div className="row no-gutters">
                <div className="col-md-15 ps-0 ps-lg-3 pe-lg-1 d-flex align-items-stretch">
                    <div className="content d-flex flex-column justify-content-center">
                        <center>
                            <h3 style={{ 'color': 'black' }} data-aos="fade-up">Our History</h3>
                        </center>
                        <div className="container">
                            <div className="timeline">
                                <div className="line text-muted"></div>
                                {historyItems.map(item => {
                                    return (
                                        <React.Fragment key={item.date}>
                                            <div className="separator text-muted">
                                                <time>{item.date} - Business Growth : {item.growth}</time>
                                            </div>
                                            <article className="panel panel-primary">
                                                <div className="panel-body" style={{ 'fontSize': '15px' }}>
                                                    {item.content}
                                                </div>
                                            </article>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="quality" className="about-us">
            <div className="container">
                <div className="row no-gutters">
                    <div className="image col-xl-6 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-duration="900">
                        <img src="assets/img/site-images/quality.png" className="img-fluid" alt="q" />
                    </div>
                    <div className="col-xl-6 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
                        <div className="container">
                            <div className="content d-flex flex-column">
                                <center>
                                    <h3 style={{ 'color': 'black' }} data-aos="fade-up" data-aos-duration="900">Quality</h3>
                                </center>
                                <p className="text-center" data-aos="fade-up" data-aos-duration="900">
                                    Quality is our responsibility at MacPro, that we carry out with utmost care.
                                    While manufacturing tools and components at micron precision, the maintenance of its pristine quality is important.
                                    We strive to achieve this by subjecting parts to meticulous inspection at all stages from Design to Component Production. We, at MacPro, are equipped with state of the art Quality Control instruments to ensure that the right parts are delivered.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container">
            <center>
                <h3 style={{ 'color': 'black' }} data-aos="fade-up">Certificates</h3>
            </center>
            <div className="list-group">
                <a target="_blank" href="assets/img/certificates/IATF 16949.pdf" className="list-group-item list-group-item-action">IATF 16949</a>
                <a target="_blank" href="assets/img/certificates/ISO 9001  2015.pdf" className="list-group-item list-group-item-action">ISO 9001 2015</a>
            </div>
        </div>
        <br />
        <div className="container">
            <center>
                <h3>Our Presence</h3>
                <br />
            </center>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="card col-md-3 shadow shadow-lg">
                        <br />
                        <div className="alert alert-sm alert-info">
                            MacPro Technologies
                        </div>
                        <p>
                            10/1 A1 Hoodi village,<br />
                            Graphit India Ltd road,<br />
                            Krishnarajapuram,Hobli,<br />
                            Bengaluru 560048,<br />
                            Karnataka, India <br /><br />
                        </p>
                    </div>
                    &nbsp;
                    <div className="card col-md-3 shadow shadow-lg">
                        <br />
                        <div className="alert alert-sm alert-info">
                            Terminal Technologies
                        </div>
                        <p>
                            Terminal House,<br />
                            Merchant Industrial Complex,<br />
                            Valiv, Vasai (East),<br />
                            District Palghar 401 208,<br />
                            Maharashtra, India <br /><br />
                        </p>
                    </div>
                    &nbsp;
                    <div className="card col-md-3 shadow shadow-lg">
                        <br />
                        <div className="alert alert-sm alert-info">
                            Terminal Technologies
                        </div>
                        <p>
                            Gut No. 312/2/3,<br />
                            Nanekarwadi Industrial Area,<br />
                            Alandi Phata, Chakan,<br />
                            Taluka Khed, Pune 410 501,<br />
                            Maharashtra, India <br /><br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
    </React.Fragment>

}