import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import About from './About';
import Product from './Product';
import Facility from './Facility';
import Career from './Career';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/products">
          <Product />
        </Route>
        <Route exact path="/facilities">
          <Facility />
        </Route>
        <Route exact path="/career">
          <Career />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

