import React from 'react';

export default function Home() {
  return (
    <React.Fragment>
      <main id="main">
        <section id="cta" className="cta">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 text-center text-lg-left">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <h3>AN <span>ISO 9001: 2015/IATF 16949:2016</span> COMPANY</h3>
                <p>We've manufactured more than 100 moulds this year!</p>
              </div>
              <div className="col-lg-2 cta-btn-container text-center">
                <img src="assets/img/certificates/iso.png" className="img-fluid" />
                <img src="assets/img/certificates/tuv.png" className="img-fluid" />
              </div>
            </div>
            <div className="counter-container row">
              <div className="four col-md-3">
                <div className="counter-box colored"> <i className="fa fa-group"></i> <span className="counter">200
                </span>
                  <p>Employees</p>
                </div>
              </div>
              <div className="four col-md-3">
                <div className="counter-box"> <i className="fa fa-gears"></i> <span className="counter">60
                </span>
                  <p>Machines</p>
                </div>
              </div>
              <div className="four col-md-3">
                <div className="counter-box"> <i className="fa fa-user"></i> <span className="counter">40
                </span>
                  <p>Customers</p>
                </div>
              </div>
              <div className="four col-md-3">
                <div className="counter-box"> <i className="fa fa-briefcase"></i> <span className="counter">2000
                </span>
                  <p>Projects</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <div className="container">
          <div className="row no-gutters">
            <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right">
              <center>
                <div className="col-sm-8">
                  <img src="assets/img/site-images/overview.jpg" className="img-fluid" alt="q" />
                </div>
              </center>
            </div>
            <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
              <div className="container">
                <div className="content d-flex flex-column">
                  <center>
                    <h3 data-aos="fade-up">&nbsp;</h3>
                  </center>
                  <p data-aos="fade-up" className="text-center">
                    Incorporated in May 2010, Started its operation with minimum machineries and design capabilities, with the industrial expertise MacPro has grown rapidly. With more than 11 years of excellence in High Precision, Plastic Injection molds, Stamping Dies, components and Sub Assemblies, we have a stellar group turnover of 200+ Crore. We’ve got significant global exposure in Precision Manufacturing & Design, Stamping and Setting up of assembly lines. We believe in the concepts of ‘Quality First’ and ‘Built to Print,’ the industrious following of which has helped us become a leading force in the tooling and production industry in the country.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="services" className="services">
          <div className="section-title" data-aos="zoom-in" data-aos-duration="900">
            <h2>Our <strong>Products</strong></h2>
          </div>
          <div className="services container">
            <div className="row">

              <div className="col-lg-4 col-md-6">
                <div className="icon-box" data-aos="zoom-in" data-aos-duration="900" data-aos-delay="200">
                  <div className="icon"><i className="bx bx-hive"></i></div>
                  <h4 className="title">Tool Room</h4>
                  <p className="description">
                    <a href="/products" className="btn-get-started">View Products</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box" data-aos="zoom-in" data-aos-duration="900" data-aos-delay="400">
                  <div className="icon"><i className="bx bx-shape-circle"></i></div>
                  <h4 className="title">Components</h4>
                  <p className="description">
                    <a href="/products" className="btn-get-started">View Products</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box" data-aos="zoom-in" data-aos-duration="900">
                  <div className="icon"><i className="bx bx-cog"></i></div>
                  <h4 className="title">Value Added Services</h4>
                  <p className="description">
                    <a href="/products" className="btn-get-started">View Products</a>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section id="clients" className="clients">
          <div className="container">

            <div className="section-title" data-aos="zoom-in" data-aos-duration="900">
              <h2>Our <strong>Clients</strong></h2>
            </div>

            <div id="clients" className="row no-gutters clients-wrap clearfix" data-aos="fade-up" data-aos-duration="900">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(i => {
                return (<div key={i} className="col-lg-2 col-md-3 col-xs-7" style={{ 'cursor': 'pointer' }}>
                  <div className="client-logo">
                    <img src={"assets/img/clients/client-" + i + ".png"} className="img-fluid" alt="item" />
                  </div>
                </div>);
              })}
            </div>

          </div>
        </section>
        <div className="map-section container" id="our-presence">
          <div className="section-title" data-aos="fade-up">
            <h2>Our <strong>Presence</strong></h2>
          </div>
          <iframe title="our-presence" style={{ "border": "0", "width": "100%", "height": "350px" }} src="https://www.google.com/maps/d/embed?mid=1vllcC000v8rw2cJx3gOMBwWiSKtSrHVE" frameBorder="0" allowFullScreen></iframe>
        </div>
        <br />
        <br />
      </main>
    </React.Fragment>
  );
}

