import React from "react";

export default function Product(props) {
    const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);
    return <React.Fragment>
        <section id="pricing" className="pricing">
            <div className="container">
                <div className="section-title" data-aos="zoom-in" data-aos-duration="700">
                    <h2><strong>Products </strong>by MacPro Technologies</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="box" data-aos="fade-right">
                        <div className="btn-wrap">
                                <a href="#" className="btn-buy" style={{'fontSize':'110%','backgroundColor':'lightgray','color':"black",'borderColor':"black"}}>Tool Room</a>
                            </div>
                            <ul>
                                <li>Connector Mould</li>
                                <li>2K Injection Mould</li>
                                <li>Over Mould/Insert Mould</li>
                                <li>High Speed Stamping Dies</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                        <div className="box" data-aos="fade-left">
                        <div className="btn-wrap">
                                <a href="#" className="btn-buy" style={{'fontSize':'110%','backgroundColor':'lightgray','color':"black",'borderColor':"black"}}>Components</a>
                            </div>
                            <ul>
                                <li>Plastic Injection Moulded Components</li>
                                <li>Insert Moulding</li>
                                <li>Over Moulding</li>
                                <li>2k Injection Moulding</li>
                                <li>High Speed Stamping</li>
                                <li>In Die Contacts Riveting</li>
                                <li>Progressive Sheet Metal Forming & Stamping</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                        <div className="box" data-aos="fade-up">
                        <div className="btn-wrap">
                                <a href="#" className="btn-buy" style={{'fontSize':'110%','backgroundColor':'lightgray','color':"black",'borderColor':"black"}}><strong>Value Added Services</strong></a>
                            </div>
                            <ul>
                                <li>Tampon Printing</li>
                                <li>Laser Marking</li>
                                <li>Laser Welding</li>
                                <li>Tool Polishing</li>
                                <li>Jigs & Fixtures</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                            </ul>
                        </div>
                    </div>

                    

                </div>

            </div>
        </section>

        <section id="portfolio" className="portfolio">
            <div className="container">
                <center>
                    <h3>Gallery</h3>
                </center>
                <div className="row" data-aos="fade-up">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li data-filter=".mnd">Mould & Die</li>
                            <li data-filter=".ism">Insert Moulding</li>
                            <li data-filter=".crp">Cover Parts</li>
                            <li data-filter=".cnp">Connector Parts</li>
                            <li data-filter=".smp">Stamping Parts</li>
                            <li data-filter=".amp">Automobile Parts</li>
                        </ul>
                    </div>
                </div>
                <div className="row portfolio-container justify-content-around" data-aos="fade-up">

                    {range(1, 18).map(i => {
                        return (
                            <div key={i} className="col-sm-2 portfolio-item cnp">
                                <img src={"assets/img/products/cnp/" + i + ".jpg"} className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <a href={"assets/img/products/cnp/" + i + ".jpg"} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link">
                                        <i className="bx bx-image-alt"></i></a>
                                </div>
                            </div>
                        )
                    })}
                    {range(1, 7).map(i => {
                        return (
                            <div key={i} className="col-sm-2 portfolio-item smp">
                                <img src={"assets/img/products/smp/" + i + ".jpg"} className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <a href={"assets/img/products/smp/" + i + ".jpg"} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link">
                                        <i className="bx bx-image-alt"></i></a>
                                </div>
                            </div>
                        )
                    })}
                    {range(1, 5).map(i => {
                        return (
                            <div key={i} className="col-sm-2 portfolio-item mnd">
                                <img src={"assets/img/products/mnd/" + i + ".jpg"} className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <a href={"assets/img/products/mnd/" + i + ".jpg"} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link">
                                        <i className="bx bx-image-alt"></i></a>
                                </div>
                            </div>
                        )
                    })}
                    {range(1, 13).map(i => {
                        return (
                            <div key={i} className="col-sm-2 portfolio-item amp">
                                <img src={"assets/img/products/amp/" + i + ".jpg"} className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <a href={"assets/img/products/amp/" + i + ".jpg"} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link">
                                        <i className="bx bx-image-alt"></i></a>
                                </div>
                            </div>
                        )
                    })}
                    {range(1, 21).map(i => {
                        return (
                            <div key={i} className="col-sm-2 portfolio-item ism">
                                <img src={"assets/img/products/ism/" + i + ".jpg"} className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <a href={"assets/img/products/ism/" + i + ".jpg"} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link">
                                        <i className="bx bx-image-alt"></i></a>
                                </div>
                            </div>
                        )
                    })}
                    {range(1, 6).map(i => {
                        return (
                            <div key={i} className="col-sm-2 portfolio-item crp">
                                <img src={"assets/img/products/crp/" + i + ".jpg"} className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <a href={"assets/img/products/crp/" + i + ".jpg"} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link">
                                        <i className="bx bx-image-alt"></i></a>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <br />
                <br />
                <br />
            </div>
        </section>
    </React.Fragment>;

}